<template>
  <router-view />
  <ul class="nav nav-pills">
    <li class="nav-item" :class="activeClass('/organizer')">
      <router-link to="/organizer" class="nav-link">
        <div class="nav-icon">
          <i class="fa-solid fa-wand-magic-sparkles fa-lg"></i>
        </div>
        <div class="nav-item-title">
          AI
        </div>
      </router-link>
    </li>
    <li class="nav-item" :class="activeClass('/trips')">
      <router-link to="/trips" class="nav-link">
        <div class="nav-icon">
          <i class="fa-solid fa-plane fa-lg"></i>
        </div>
        <div class="nav-item-title">
          Trips
        </div>
      </router-link>
    </li>
    <li class="nav-item" :class="activeClass('/map')">
      <router-link to="/map" class="nav-link">
        <div class="nav-icon">
          <i class="fa-solid fa-location-dot fa-lg"></i>
        </div>
        <div class="nav-item-title">
          Map
        </div>
      </router-link>
    </li>
    <li class="nav-item" :class="activeClass('/notes')">
      <router-link to="/notes" class="nav-link">
        <div class="nav-icon">
          <i class="fa-solid fa-pen-nib fa-lg"></i>
        </div>
        <div class="nav-item-title">
          Notes
        </div>
      </router-link>
    </li>
    <li class="nav-item" :class="activeClass('/profile')">
      <router-link to="/profile" class="nav-link">
        <div class="nav-icon">
          <i v-if="!user" class="fa-solid fa-user fa-lg"></i>
          <Avatar v-if="user" :label="note.user.firstName.charAt(0) + note.user.lastName.charAt(0)" shape="circle" />
        </div>
        <div class="nav-item-title">
          Profile
        </div>
      </router-link>
    </li>
  </ul>
</template>

<script>
/*eslint-disable no-unused-vars*/
import { ref, onMounted } from 'vue';
import { router } from './router/index';
import { useAuthStore } from '@/stores/auth';

const user = ref({});
router.beforeEach((to) => {
  const authStore = useAuthStore();
  if (authStore.user) {
    user.value = authStore.user;
  }
})

onMounted(() => {
})

const activeClass = (name) => {
  if (router.currentRoute.value.path.startsWith(name) === true)
    return 'active';
}

export default {
  name: 'App',
  methods: {
    activeClass
  }
}
</script>

<style>
body {
  height: 100%;
  width: 100%;
  margin: 0;
  background-color: var(--p-surface-0) !important;
  font-family: "Josefin Sans", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.p-select-label {
  margin: auto 0;
}

.p-card-body {
  padding: 0.85em !important;
}

.p-card-content {
  flex-grow: 1;
}

.p-timeline-event:last-child {
  min-height: var(--p-timeline-event-min-height) !important;
}

.p-timeline-vertical .p-timeline-event-opposite {
  padding: 0 1em 0 0 !important;
}

.p-timeline-vertical .p-timeline-event-content {
  padding: 0 0 0 1em !important;
}

.p-inputtext {
  flex-grow: 1;
}

::-webkit-scrollbar {
  display: none;
}

a {
  color: #888;
  text-decoration: none;
  font-weight: 500;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.margin-vertical {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.ma {
  margin: auto;
}

.mw-100 {
  min-width: 100px;
}

#app {
  position: absolute;
  text-align: center;
  color: var(--p-primary-900);
  width: 100vw;
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
  margin: 0 auto;
}

.nav {
  width: 100%;
  height: 60px;
  background-color: #fff;
  border-top: 1px solid rgb(200, 200, 200);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  z-index: 1;
  position: fixed;
  bottom: 0;
  margin: 0;
}

.nav-item {
  width: auto;
  height: 60px;
  padding: 10px 2px;
  color: var(--p-primary-900) !important;
}

.nav-item-title {
  color: var(--p-primary-900);
}

.nav-icon {
  margin-bottom: 6px;
  color: var(--p-primary-900);
}

.active {
  color: var(--p-primary-color) !important;
}

.active .nav-icon {
  color: var(--p-primary-color) !important;
}

.active .nav-item-title {
  color: var(--p-primary-color) !important;
}

.disabled {
  background-color: #888 !important;
}

.btn {
  width: fit-content;
  margin: auto;
  padding: 0.4em 1em;
  font-weight: 600;
  color: #fff;
  background: var(--p-primary-color);
  border: none;
  text-shadow: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background 0.3s;
  text-shadow: -1px 0 #666, 0 1px #666, 1px 0 #666, 0 -1px #666;
}

.btn:hover {
  background: #0c8c61;
}

.skeletons {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}
</style>
