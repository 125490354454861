/*eslint-disable no-unused-vars*/
import axios from 'axios';
import { createRouter, createWebHistory } from 'vue-router';

import MapView from '../views/MapView.vue';
import TripsView from '../views/TripsView.vue';
import TripView from '../views/TripView.vue';
import NotesView from '../views/NotesView.vue';
import Login from '../views/Login.vue';
import Register from '../views/Register.vue';
import { useAuthStore } from '../stores/auth.js';

export const router = createRouter({
    history: createWebHistory('/'),
    linkActiveClass: 'active',
    routes: [
        { path: '/', component: TripsView },
        { path: '/map', component: MapView },
        { path: '/map/:ids', component: MapView },
        { path: '/trips', component: TripsView },
        { path: '/trips/:tripId', component: TripView },
        { path: '/notes', component: NotesView },
        { path: '/profile', component: Login },
        { path: '/register', component: Register },
        // catch all redirect to home page
        { path: '/:pathMatch(.*)*', redirect: '/' }
    ]
});

router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore();
    const token = authStore.token;
    if (to.fullPath === '/profile') {
        next();
    } else if (token) {
        try {
            await authStore.getUser();
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            next();
        } catch (error) {
            console.log(error);
            next('/profile');
        }
    } else {
        axios.defaults.headers.common['Authorization'] = null;
        /*if setting null does not remove `Authorization` header then try     
          delete axios.defaults.headers.common['Authorization'];
        */
        next('/profile');
    }
});
