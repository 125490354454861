/*eslint-disable no-unused-vars*/
import { shallowRef, markRaw } from 'vue';
import { defineStore } from 'pinia';
import { googleMapsKey, googleMapsId } from '../config';
import { usePOIStore } from '@/stores/pois';

const ICONS = {
    "tourist_attraction": "monument",
    "point_of_interest": "monument",
    "landmark": "landmark",
    "historical_landmark": "landmark",
    "monument": "landmark",
    "city_hall": "landmark",
    "museum": "building-columns",
    "art_gallery": "palette",
    "cultural_center": "building",
    "book_store": "book",
    "church": "church",
    "route": "road",
    "restaurant": "utensils",
    "steak_house": "utensils",
    "ice_cream_shop": "ice-cream",
    "bar": "martini-glass-citrus",
    "bakery": "bread-slice",
    "cafe": "mug-saucer",
    "grocery_store": "cart-shopping",
    "market": "store",
    "library": "book",
    "shopping_mall": "bag-shopping",
    "park": "tree",
    "natural_feature": "tree",
    "train_station": "train",
    "neighborhood": "tree-city",
    "stadium": "futbol",
    "gym": "dumbbell",
    "spa": "spa",
    "housing": "house",
    "car_rental": "car"
}

export const useMapStore = defineStore('map-store', {
    state: () => {
        return {
            key: shallowRef(googleMapsKey),
            id: shallowRef(googleMapsId),
            map: shallowRef(null),
            gmap: shallowRef(null),
            loader: shallowRef(null),
            currentPosition: shallowRef([null, null]),
            markers: [],
            infowindows: []
        }
    },
    actions: {
        setMap(map) {
            this.map = map
        },
        setGMap(map) {
            this.gmap = map
        },
        setLoader(loader) {
            this.loader = loader
        },
        getFaIcon(type) {
            if (ICONS[type]) {
                return "fa-" + ICONS[type];
            }
            if (type.indexOf("restaurant") != -1 || type.indexOf("meal") != -1) {
                return "fa-utensils";
            }
            if (type.indexOf("store") != -1) {
                return "fa-store";
            }
            if (type.indexOf("theater") != -1) {
                return "fa-landmark";
            }
            if (type.indexOf("center") != -1) {
                return "fa-building";
            }
            return "fa-circle";
        },
        getPinOptions(id, icon, type, done) {
            let pinOptions = {
                glyph: icon,
                glyphColor: "#525252",
                background: "#a0a0a0",
                borderColor: "#525252",
                scale: 1.2
            }
            if (done === true) {
                return pinOptions;
            }

            if (type.indexOf("restaurant") != -1 || type.indexOf("meal") != -1) {
                pinOptions.glyph = icon;
                pinOptions.glyphColor = "#ff8300";
                pinOptions.background = "#FFD514";
                pinOptions.borderColor = "#ff8300";
            }
            else if (type.indexOf("store") != -1) {
                pinOptions.glyph = icon;
                pinOptions.glyphColor = "#ff8300";
                pinOptions.background = "#FFD514";
                pinOptions.borderColor = "#ff8300";
            }
            else if (type.indexOf("theater") != -1) {
                pinOptions.glyph = icon;
                pinOptions.glyphColor = "#428BFF";
                pinOptions.background = "#afceff";
                pinOptions.borderColor = "#428BFF";
            }
            else if (type.indexOf("center") != -1) {
                pinOptions.glyph = icon;
                pinOptions.glyphColor = "#428BFF";
                pinOptions.background = "#afceff";
                pinOptions.borderColor = "#428BFF";
            }
            else {
                switch (type) {
                    case "point_of_interest":
                    case "tourist_attraction":
                    case "landmark":
                    case "historical_landmark":
                    case "city_hall":
                    case "museum":
                    case "art_gallery":
                    case "book_store":
                    case "church":
                    case "route":
                    case "train_station":
                        pinOptions.glyph = icon;
                        pinOptions.glyphColor = "#428BFF";
                        pinOptions.background = "#afceff";
                        pinOptions.borderColor = "#428BFF";
                        break;
                    case "restaurant":
                    case "bar":
                    case "cafe":
                    case "bakery":
                        pinOptions.glyph = icon;
                        pinOptions.glyphColor = "#ff8300";
                        pinOptions.background = "#FFD514";
                        pinOptions.borderColor = "#ff8300";
                        break;
                    case "park":
                    case "natural_feature":
                        pinOptions.glyph = icon;
                        pinOptions.glyphColor = "#008A05";
                        pinOptions.background = "#9be19e";
                        pinOptions.borderColor = "#008A05";
                        break;
                    case "housing":
                        pinOptions.glyph = icon;
                        pinOptions.glyphColor = "#fff";
                        pinOptions.background = "#FF385C";
                        pinOptions.borderColor = "#FF385C";
                        break;
                    case "car_rental":
                        pinOptions.glyph = icon;
                        pinOptions.glyphColor = "#fff";
                        pinOptions.background = "#23ae4a";
                        pinOptions.borderColor = "#23ae4a";
                        break;
                    default:
                        pinOptions.glyph = icon;
                        pinOptions.glyphColor = "#92174D";
                        pinOptions.background = "#f377ad";
                        pinOptions.borderColor = "#92174D";
                        break;
                }
            }
            return pinOptions;
        },
        async setCurrentPositionWithMarker(latitude, longitude) {
            this.currentPosition = [latitude, longitude];
            console.log("Current position", this.currentPosition);

            const { LatLng } = await this.loader.importLibrary("core");
            const { PinView, AdvancedMarkerElement, AdvancedMarkerView } = await this.loader.importLibrary("marker");

            const icon = document.createElement("div");
            icon.innerHTML = '<i class="fa-solid fa-location-crosshairs"></i>';

            const faPinView = new PinView({
                glyph: icon,
                scale: 1.2
            });

            const faMarkerView = new AdvancedMarkerView({
                map: this.gmap,
                position: new LatLng(latitude, longitude),
                content: faPinView.element,
                title: "Current location",
            });

            this.addMarker("me", faMarkerView);
        },
        async createGMarkers(places) {
            const { LatLng } = await this.loader.importLibrary("core");
            const { InfoWindow } = await this.loader.importLibrary("maps");
            const { PinView, AdvancedMarkerElement, AdvancedMarkerView } = await this.loader.importLibrary("marker");

            places.forEach(place => {
                const contentString = '<div style="max-width:100%;"><div style="display:grid;grid-template-rows:140px 1fr;gap:0.6em;">'
                    + '<div><img style="max-width:100%;max-height:100%;border-radius:4px;" src="https://pub-b93990d14f63428d91fefbf1f1143791.r2.dev/' + place.image + '"/></div>'
                    + '<div><div style="text-align:center;font-size:22px;font-weight:bolder;">'
                    + place.name + '</div>'
                    + '<div style="margin-top:6px;">' + place.description + '</div></div>'
                    + '</div><div style="margin-top:10px;color:#4d5156;">' + place.infos + '</div>'
                    + '</div>';
                const infowindow = new InfoWindow({
                    content: contentString,
                    ariaLabel: "V",
                });
                this.infowindows.push(infowindow);

                const icon = document.createElement("div");
                icon.innerHTML = '<i class="fa ' + this.getFaIcon(place.type) + ' fa-lg"></i>';

                const faPinView = new PinView(this.getPinOptions(place._id, icon, place.type, place.done));
                const faMarkerView = new AdvancedMarkerView({
                    map: this.gmap,
                    position: new LatLng(place.location.coordinates[0], place.location.coordinates[1]),
                    content: faPinView.element,
                    title: "A marker using a FontAwesome icon for the glyph.",
                });
                faMarkerView.addListener("gmp-click", () => {
                    this.infowindows.forEach(iw => iw.close());
                    /*infowindow.open({
                        anchor: faMarkerView,
                        map: this.gmap,
                    });*/
                    this.highlightMarker(place._id);
                });
                this.addMarker(place._id, faMarkerView);
            });

            this.gmap.addListener("click", () => {
                this.closeInfowindows();
            });
        },
        closeInfowindows() {
            this.infowindows.forEach(iw => iw.close());
        },
        addMarker(id, marker) {
            this.markers.push({
                id: id,
                marker: marker
            })
        },
        highlightMarker(id) {
            const poiStore = usePOIStore();
            poiStore.currentPlace.value = poiStore.places.find(p => p._id === id);
        },
        backToDefaultMarkersState() {
            /*this.markers.forEach(m => {
                let icon = m.marker.getIcon();
                icon.options.iconSize = [26, 26];
                m.marker.setIcon(icon)
            })*/
        },
        async moveTo(lat, lng) {
            const { LatLng, LatLngBounds } = await this.loader.importLibrary("core");
            this.gmap.panTo(new LatLng(lat, lng));

            /*const poiStore = usePOIStore();
            let southWest = [null, null];
            let northEast = [null, null];

            const bounds = new LatLngBounds();
            poiStore.places.forEach(place => {
                // if (southWest[0] == null || southWest[1] == null) {
                //     southWest[0] = place.location.coordinates[0];
                //     southWest[1] = place.location.coordinates[1];
                //     northEast[0] = place.location.coordinates[0];
                //     northEast[1] = place.location.coordinates[1];
                // } else if (place.location.coordinates[0] < southWest[0] && place.location.coordinates[1] < southWest[1]) {
                //     southWest[0] = place.location.coordinates[0];
                //     southWest[1] = place.location.coordinates[1];
                // } else if (place.location.coordinates[0] > northEast[0] && place.location.coordinates[1] > northEast[1]) {
                //     northEast[0] = place.location.coordinates[0];
                //     northEast[1] = place.location.coordinates[1];
                // }
                console.log(bounds);
                bounds.extend(new LatLng(place.location.coordinates[0], place.location.coordinates[1]));
            })
            // console.log(southWest, northEast);
            // bounds.extend(new LatLng(southWest[0], southWest[1]));
            // bounds.extend(new LatLng(northEast[0], northEast[1]));
            this.gmap.fitBounds(bounds);*/
        },
        async moveToMarker(id) {
            const { LatLng } = await this.loader.importLibrary("core");
            let marker = this.markers.find(m => m.id === id);
            if (marker) {
                this.gmap.panTo(new LatLng(marker.marker.position.lat, marker.marker.position.lng));
            }
        }
    },
})