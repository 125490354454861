<template>
    <div class="container">
        <div class="trip-header">
            <div class="trip-main" v-if="trip._id" :style="trip.destinations[0] ?
                'background-image: url(https://pub-b93990d14f63428d91fefbf1f1143791.r2.dev/' + trip.destinations[0].image
                : 'background-color: #666'">
                <div class="trip-destination justify-center">
                    <Select v-model="tripId" :options="tripStore.trips" optionLabel="name" optionValue="_id"
                        placeholder="Select a Trip" class=""></Select>
                </div>

                <div class="trip-country">{{ trip.country }}</div>
                <div class="trip-date">
                    {{ new Date(trip.dates[0]).toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric'
            }) }}
                    -
                    {{ new Date(trip.dates[1]).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            }) }}
                </div>
                <div class="trip-poi-list-options">
                    <div class="trip-poi-list-options-left">
                        <div class="trip-map">
                            <Button icon="pi pi-map" label="Map" @click="goToTripMap($event, trip)" rounded></Button>
                        </div>
                        <div class="flex justify-center ma">
                            <Button icon="pi pi-copy" size="small" @click="copyToClipboard" rounded></Button>
                        </div>
                        <div class="flex justify-center">
                            <Button icon="pi pi-plus" size="small" label="POI" @click="openPOIModal()" rounded></Button>
                        </div>
                        <div class="flex justify-center mw-100">
                            <Button size="small" @click="openOrganizer()" rounded>
                                <!-- :disabled="!authStore.user.isPremium" -->
                                <div>
                                    <i class="fa-solid fa-list"></i>
                                    Travel
                                    <PremiumBadge></PremiumBadge>
                                </div>
                            </Button>
                        </div>
                    </div>
                    <div class="avatars">
                        <Avatar :label="user.firstName.charAt(0) + user.lastName.charAt(0)" shape="circle"
                            v-for="user in trip.users" :key="user._id" />
                        <div class="p-avatar p-component p-avatar-circle" @click="openUserModal()"><span
                                class="p-avatar-label" data-pc-section="label"><i class="fa-solid fa-plus fa-sm"></i><i
                                    class="fa fa-user fa-sm"></i></span></div>
                    </div>
                </div>
            </div>
            <ModalComponent :isOpen="isPOIModalOpened" @modal-close="closeModal" name="first-modal">
                <template #header>New POI</template>
                <template #content>
                    <div>
                        <div class="trip-poi-search">
                            <input v-model="searchText" class="trip-poi-input" type="text" id="poiSearch"
                                @keyup.enter="searchPOI($event, trip, selectedType)" track-by="id" />

                            <div class="trip-poi-search-enter" @click="searchPOI($event, trip, selectedType)">Search
                            </div>
                        </div>
                        <div class="new-poi-form-item">
                            <div>Accomodation?</div>
                            <div>
                                <ToggleButton v-model="selectedType" onLabel="Yes" offLabel="No" />
                            </div>
                        </div>

                    </div>
                </template>
                <template #footer>
                    <div class="skeletons" v-if="isSearching">
                        <Skeleton height="1.5rem" width="6rem" class="mb-2"></Skeleton>
                        <div style="display: flex; justify-content: center;">
                            <Skeleton height="5rem" width="7rem" class="mb-2"></Skeleton>
                        </div>
                        <Skeleton height="1rem" class="mb-2"></Skeleton>
                        <Skeleton height="1rem" class="mb-2"></Skeleton>
                    </div>
                    <div class="trip-poi-found" v-if="poiStore.foundPOI && poiStore.foundPOI.trip_id == trip._id">
                        <div>

                            <div>POI found:</div>
                            <div>
                                <img style="width: auto;max-width: 100%;max-height: 33vh;"
                                    :src="'https://pub-b93990d14f63428d91fefbf1f1143791.r2.dev/' + poiStore.foundPOI.image" />
                            </div>
                            <div>{{ poiStore.foundPOI.name }}</div>
                            <div>{{ poiStore.foundPOI.address }}</div>
                            <input v-model="poiNotes" class="trip-poi-input" type="text" />

                        </div>
                    </div>
                    <div :class="poiStore.foundPOI && poiStore.foundPOI.trip_id == trip._id ? 'trip-poi-validate' : 'trip-poi-validate disabled'"
                        @click="addPOI()">Validate</div>
                </template>
            </ModalComponent>

            <ModalComponent :isOpen="addUserModal" @modal-close="closeUserModal" name="first-modal">
                <template #header>Add User</template>
                <template #content>
                    <div>
                        <div class="user-search">
                            <InputText id="userSearch" v-model="searchText" placeholder="Search by name or email" />
                            <Button icon="pi pi-search" @click="searchUser($event, trip)" size="small" rounded />
                        </div>
                        <div class="user-list">

                            <div class="skeletons" v-if="isSearching">
                                <div style="display: flex; gap: 1em;">
                                    <Skeleton shape="circle" size="2.5rem" class="mr-2"></Skeleton>
                                    <Skeleton height="2rem" width="10rem" class="mb-2"></Skeleton>
                                </div>
                                <div style="display: flex; gap: 1em;">
                                    <Skeleton shape="circle" size="2.5rem" class="mr-2"></Skeleton>
                                    <Skeleton height="2rem" width="10rem" class="mb-2"></Skeleton>
                                </div>
                            </div>
                            <div class="user-item" v-for="user in userStore.users" :key="user._id">
                                <Avatar :label="user.firstName.charAt(0) + user.lastName.charAt(0)" shape="circle" />
                                <div class="user-item-name">
                                    <div>
                                        {{ user.firstName }} {{ user.lastName.charAt(0) + "." }}
                                    </div>
                                    <div class="user-item-subname">
                                        {{ user.username.split('@')[0] }}
                                    </div>
                                </div>
                                <div class="user-actions">
                                    <Button v-if="!trip.users.some(u => u._id === user._id)" label="+"
                                        @click="addUser(trip, user)" severity="success" rounded outlined />
                                    <Button v-if="trip.users.some(u => u._id === user._id)" label="—"
                                        @click="removeUser(trip, user)" severity="danger" rounded />
                                </div>
                            </div>
                        </div>

                    </div>
                </template>
            </ModalComponent>

            <div class="card flex justify-center">
                <Drawer v-model:visible="organizerOpened" header="Trip Organizer" position="full">
                    <div class="flex justify-center margin-vertical">
                        <InputText id="newTripName" v-model="name" placeholder="Name" />
                    </div>
                    <div class="flex justify-center margin-vertical">
                        <SelectButton v-model="selectedTripStyles" :options="tripStyles" optionLabel="name" multiple />
                    </div>
                    <div class="flex justify-center margin-vertical">
                        <Button @click="organize(trip)" :disabled="destination === ''" rounded>
                            <i class="pi pi-sparkles"></i>Organize<i class="pi pi-sparkles"></i>
                        </Button>
                    </div>
                    <div>
                        <div class="skeletons" v-if="isSearching">
                            <Skeleton height="5rem" width="7rem" class="mb-2"></Skeleton>
                            <Skeleton height="1rem" width="100%" class="mb-2"></Skeleton>
                            <Skeleton height="1rem" width="100%" class="mb-2"></Skeleton>
                        </div>
                        <div class="trip-pois" v-if="poiStore.aiPlaces">

                            <!-- <POIComponent v-bind:poi="poi" /> -->

                            <div class="trip-poi-item" v-for="poi in poiStore.aiPlaces" :key="poi._id">
                                <div v-if="poi.image" class="trip-poi-image">
                                    <img :src="'https://pub-b93990d14f63428d91fefbf1f1143791.r2.dev/' + poi.image"
                                        :class="{ 'trip-poi-done': poi.done === true }" class="trip-poi-image-img" />
                                </div>
                                <div class="trip-poi-infos">
                                    <div class="trip-poi-infos-core">
                                        <div class="trip-poi-name">
                                            {{ poi.name }}
                                        </div>
                                        <div class="trip-poi-type">
                                            <i :class="'fa ' + mapStore.getFaIcon(poi.type) + ' fa-lg'"></i>
                                            <span class="trip-poi-type-label">{{ poiStore.getLabel(poi.type) }}</span>
                                        </div>
                                    </div>

                                    <div class="trip-poi-infos-more">
                                        <div class="trip-poi-address">
                                            <i class="fa-solid fa-location-dot fa-sm"></i> {{ poi.address }}
                                        </div>
                                        <div class="trip-poi-description">
                                            <i class="fa-solid fa-file-lines fa-sm"></i>
                                            <div>
                                                <div class="trip-poi-text">
                                                    {{ poi.description ? poi.description : "<Add description>"
                                                        }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="flex justify-center margin-vertical" v-if="poiStore._aiPlaces.length > 0">
                        <Button icon="pi pi-check" label="Validate" @click="validateOrganizer()"
                            :disabled="!poiStore._aiPlaces.length > 0" rounded></Button>
                    </div>
                </Drawer>
            </div>
        </div>

        <div class="trip" v-if="trip._id">
            <div class="trip-middle">
                <Accordion :value="['0']" multiple>
                    <AccordionPanel v-for="(destination, idx) in trip.destinations" :key="destination._id" :value="idx">
                        <AccordionHeader>{{ destination.name }}</AccordionHeader>
                        <AccordionContent>
                            <div class="trip-poi-item"
                                v-for="poi in poiStore.places.filter(place => place.destination_place_id === destination.place_id)"
                                :key="poi._id">
                                <div v-if="poi.image" class="trip-poi-image">
                                    <img :src="'https://pub-b93990d14f63428d91fefbf1f1143791.r2.dev/' + poi.image"
                                        :class="{ 'trip-poi-done': poi.done === true }" class="trip-poi-image-img"
                                        @click="updatePhoto(poi._id)" />
                                </div>
                                <div class="trip-poi-infos">
                                    <div class="trip-poi-infos-core">
                                        <div class="trip-poi-name">
                                            {{ poi.name }}
                                        </div>
                                        <div class="trip-poi-type">
                                            <i :class="'fa ' + mapStore.getFaIcon(poi.type) + ' fa-lg'"></i>
                                            <span class="trip-poi-type-label">{{ poiStore.getLabel(poi.type) }}</span>
                                        </div>
                                        <div class="trip-poi-checkbox">
                                            <input type="checkbox" id="checkbox" v-model="poi.done"
                                                @change="check($event, poi)" />
                                        </div>
                                    </div>

                                    <div class="trip-poi-infos-more">
                                        <div class="trip-poi-address">
                                            <i class="fa-solid fa-location-dot fa-sm"></i> {{ poi.address }}
                                        </div>
                                        <div class="trip-poi-description">
                                            <i class="fa-solid fa-file-lines fa-sm"></i>
                                            <div>
                                                <div v-if="poi._id !== editedPlace._id" class="trip-poi-text"
                                                    @click="enableEditing(poi)">
                                                    {{ poi.description ? poi.description : "<Add description>"
                                                        }}
                                                </div>
                                                <textarea v-if="poi._id === editedPlace._id" v-model="poi.description"
                                                    class="trip-poi-text" @blur="updatePlace(place)"></textarea>
                                            </div>
                                        </div>
                                        <div class="trip-poi-notes">
                                            <i class="fa-solid fa-pen-nib fa-sm"></i>
                                            <div>
                                                <div v-if="poi._id !== editedPlace._id" class="trip-poi-text"
                                                    @click="enableEditing(poi)">
                                                    {{ poi.notes ? poi.notes : "<Add notes>"
                                                        }}
                                                </div>
                                                <textarea v-if="poi._id === editedPlace._id" v-model="poi.notes"
                                                    class="trip-poi-text" @blur="updatePlace(poi)"></textarea>

                                            </div>
                                        </div>

                                        <!-- <div class="place-hours" v-if="place.regularOpeningHours">
                                <div class="place-hours-entry"
                                    v-for="(slot, index) in place.regularOpeningHours.weekdayDescriptions" :key="slot">
                                    <div :class="{ 'place-hour-highlighted': index === NOW.getDay() }">{{ slot }}
                                    </div>
                                </div>
                            </div> -->
                                        <!-- <div class="place-hours" v-if="place.regularOpeningHours">
                                <div class="place-hours-entry"
                                    v-for="(slot, index) in place.regularOpeningHours.weekdayDescriptions" :key="slot">
                                    <div :class="{ 'place-hour-highlighted': index === NOW.getDay() }">{{ slot }}
                                    </div>
                                </div>
                            </div> -->
                                    </div>
                                </div>
                            </div>
                        </AccordionContent>
                    </AccordionPanel>
                </Accordion>
            </div>
        </div>
    </div>
</template>

<script setup>
/*eslint-disable no-unused-vars*/
import 'primeicons/primeicons.css'
import { ref, onMounted, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Select from 'primevue/select';
import ModalComponent from "../components/ModalComponent.vue";
import POIComponent from "../components/POIComponent.vue";
import PremiumBadge from "../components/PremiumBadge.vue";
import { useTripStore } from '@/stores/trip';
import { usePOIStore } from '@/stores/pois';
import { useMapStore } from '@/stores/map';
import { useUserStore } from '@/stores/user';
import { useAuthStore } from '@/stores/auth';
import Button from 'primevue/button';
import ToggleButton from 'primevue/togglebutton';
import Avatar from 'primevue/avatar';
import Skeleton from 'primevue/skeleton';
import Drawer from 'primevue/drawer';
import InputText from 'primevue/inputtext';
import SelectButton from 'primevue/selectbutton';
import Accordion from 'primevue/accordion';
import AccordionPanel from 'primevue/accordionpanel';
import AccordionHeader from 'primevue/accordionheader';
import AccordionContent from 'primevue/accordioncontent';


const mapStore = useMapStore();
const tripStore = useTripStore();
const poiStore = usePOIStore();
const userStore = useUserStore();
const authStore = useAuthStore();

const router = useRouter();
const route = useRoute();

let trip = ref({});
let selectedType = ref(false);

const tripId = computed({
    get() {
        return route.params.tripId ? route.params.tripId : ""
    },
    set(tripId) {
        router.replace({ params: { tripId: tripId } })
    }
})

watch(() => route.params.tripId, (newId, oldId) => {
    tripId.value = newId;
    tripStore.setSelected(tripStore.trips.find(option => tripId.value == option._id));
    trip.value = tripStore.selected;
    reloadPOIs(tripStore.selected);
})

onMounted(() => {
    tripStore.fetchTrips().then(() => {
        tripStore.setSelected(tripStore.trips.find(option => tripId.value == option._id));
        trip.value = tripStore.selected;
        reloadPOIs();
    });
})

const selectOption = (e, option) => {
    if (option.value) {
        tripId.value = option.value;
    }
    tripStore.setSelected(tripStore.trips.find(option => tripId.value == option._id));
    reloadPOIs();
}

const reloadPOIs = () => {
    poiStore.fetchPOIs();
}

const goToTripMap = (e, trip) => {
    tripStore.setSelected(tripStore.trips.find(option => trip._id == option._id));
    router.push({ path: '/map', query: { tripId: trip._id } });
}

const goToNotes = (e, trip) => {
    tripStore.setSelected(tripStore.trips.find(option => trip._id == option.value));
    router.push({ path: '/notes', query: { tripId: trip._id } });
}

const editedPlace = ref({});
const enableEditing = (place) => {
    editedPlace.value = place;
}
const updatePlace = (place) => {
    poiStore.updatePOI(place);
    editedPlace.value = {};
}

const updatePhoto = (poiId) => {
    poiStore.updatePOIPhoto(poiId);
}

const check = (e, poi) => {
    poiStore.updatePOI(poi).then(() => {
        reloadPOIs();
    });
}

const copyToClipboard = () => {
    navigator.clipboard.writeText(poiStore.places.map(place => {
        return [place.name, place.type, place.description, place.googleMapsUri].join(' - ')
    }).join('\n'));
}

const date = ref();
const startDate = new Date();
const endDate = new Date(new Date().setDate(startDate.getDate() + 7));
date.value = [startDate, endDate];

// Add POI
const isPOIModalOpened = ref(false);
const openPOIModal = () => {
    isPOIModalOpened.value = true;
};
const closeModal = () => {
    isPOIModalOpened.value = false;
};

const searchText = ref("");
const isSearching = ref(false);
const poiNotes = ref("");

const searchPOI = async (e, trip) => {
    isSearching.value = true;
    poiStore.resetFoundPOI();
    await poiStore.findPOI(searchText.value, trip, selectedType.value === "true");
    isSearching.value = false;
}

const addPOI = () => {
    poiStore.foundPOI.notes = poiNotes.value;
    poiStore.addPOI(poiStore.foundPOI);
    searchText.value = "";
    poiNotes.value = ""
}

// Add User
const addUserModal = ref(false);
const openUserModal = () => {
    addUserModal.value = true;
};
const closeUserModal = () => {
    addUserModal.value = false;
};

const searchUser = (e, user) => {
    isSearching.value = true;
    userStore.fetchUsers(searchText.value).then(() => {
        isSearching.value = false;
    });
}
const addUser = (trip, user) => {
    tripStore.addUser(trip._id, user._id);
    searchText.value = "";
    tripStore.fetchTrips().then(() => {
        tripStore.setSelected(tripStore.trips.find(option => tripId.value == option._id));
        trip.value = tripStore.selected;
    });
}
const removeUser = (trip, user) => {
    tripStore.removeUser(trip._id, user._id);
    searchText.value = "";
    tripStore.fetchTrips().then(() => {
        tripStore.setSelected(tripStore.trips.find(option => tripId.value == option._id));
        trip.value = tripStore.selected;
    });
}

// AI Organizer
const tripStyles = ref([
    { name: 'Nature', value: 'nature' },
    { name: 'History', value: 'cultural' },
    { name: 'Chill', value: 'chill' }
]);
const selectedTripStyles = ref([]);

const organizerOpened = ref(false);
const openOrganizer = () => {
    organizerOpened.value = true;
};

const organize = async (trip) => {
    isSearching.value = true;
    poiStore.resetAIPlaces();
    await poiStore.generatePOIs(selectedTripStyles.value.map(style => style.value), trip);
    isSearching.value = false;
}
const validateOrganizer = async () => {
    for (let place of poiStore.aiPlaces) {
        await poiStore.addPOI(place);
    }
    poiStore.resetAIPlaces();
    organizerOpened.value = false;
}
</script>

<style scoped>
svg {
    margin: auto 0;
}

.container {
    display: block;
}

.trip-header {
    width: 100%;
    background-color: #fff;
    border-bottom: 2px solid #fff;
}

.trip-selector {
    margin: 1em;
}

.trip {
    box-sizing: border-box;
    width: 100vw;
}

.trips::-webkit-scrollbar {
    display: none;
}

.trip-list-item {
    margin: 1em;
    border-radius: 16px;
    background-color: #fff;
    text-align: left;
    display: grid;
    grid-template-columns: 120px 1fr;
}

.trip-main {
    padding: 1em;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-weight: 600;
    text-shadow: -1px 0 #666, 0 1px #666, 1px 0 #666, 0 -1px #666;
}

.trip-middle {
    box-sizing: border-box;
    width: 100%;
    padding: 1em;
    font-size: 11px;
    overflow: scroll;
    margin-bottom: 60px;
}

.trip-map {
    position: fixed;
    bottom: 60px;
    left: 0;
    padding: 1em 0;
    z-index: 10;
    width: 100vw;
}

.trip-destination {
    text-shadow: none;
    font-weight: normal;
    font-size: 20px;
}

.trip-destination>.p-select {
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
}

.trip-date {
    font-size: 0.9rem;
    margin-top: 1em;
}

.trip-pois-count {
    font-size: 12px;
    position: absolute;
    bottom: 1em;
    margin-bottom: 1.4em;
}

.trip-notes-count {
    font-size: 12px;
    position: absolute;
    bottom: 1em;
}

.trip-pois-extra {
    box-sizing: border-box;
    width: 100%;
}

.trip-poi {
    flex: 1;
    display: grid;
}

.trip-poi-item {
    margin-bottom: 1em;
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 0.6em;
    font-size: 12px;
}

.trip-poi-infos {
    text-align: left;
}

.trip-poi-infos-core {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto 20px;
    gap: 0.4em;
    margin: auto 0;
}

.trip-poi-infos-more {
    width: 100%;
}

.trip-poi-type {
    flex-grow: 0;
    border: solid 2px var(--p-primary-color);
    border-radius: 18px;
    padding: 0.4em 0.6em;
    background-color: var(--p-primary-color)57;
    font-size: 12px;
    font-weight: 900;
    display: inline-flex;
    height: fit-content;
    box-sizing: border-box;
}

.trip-poi-type-label {
    margin-left: 6px;
}

@media screen and (max-width: 500px) {
    .trip-poi-type-label {
        display: none;
    }
}

.trip-poi-checkbox {
    margin: auto 0;
}

.trip-poi-name {
    flex-grow: 1;
    margin: auto 0;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 600;
}

.trip-poi-address {
    margin-top: 0.6em;
    color: #666;
    display: grid;
    grid-template-columns: 10px auto;
    gap: 0.4em;
}

.trip-poi-image {
    overflow: hidden;
    border-radius: 3px;
    margin: auto;
}

.trip-poi-done {
    opacity: 0.25;
}

.trip-poi-description {
    margin-top: 0.6em;
    font-size: 13px;
    display: grid;
    grid-template-columns: 10px auto;
    gap: 0.4em;
}

.trip-poi-notes {
    margin-top: 0.6em;
    font-size: 13px;
    color: var(--p-primary-color);
    display: grid;
    grid-template-columns: 10px auto;
    gap: 0.4em;
}

.trip-poi-photo-refresh {
    position: absolute;
}

.trip-poi-list-options {
    display: flex;
    justify-content: space-between;
    margin-top: 0.6rem;
}

.trip-poi-list-options-left {
    display: flex;
    gap: 0.4rem;
}

.trip-poi-search,
.user-search {
    width: 100%;
    margin-bottom: 0.6em;
    display: flex;
    gap: 0.4em;
}

#userSearch {
    flex-grow: 1;
}

.trip-poi-found {
    margin: 1rem 0;
}

.trip-poi-input {
    flex: 4;
    box-sizing: border-box;
    width: 100%;
    padding: 0.4em;
    font-size: 16px;
}

.trip-poi-search-enter,
.trip-poi-validate {
    max-width: fit-content;
    font-weight: 600;
    color: #fff;
    background-color: var(--p-primary-color);
    border-radius: 20px;
    padding: 0.6em;
}

.modal-container {
    width: 350px;
    max-width: 80%;
}

.new-poi-form-item {
    display: flex;
    justify-content: space-between;
    gap: 1em;
    margin-bottom: 1em;
}

.new-poi-form-item>div {
    margin: auto 0;
}

.user-list {
    text-align: center;
}

.user-item {
    width: 100%;
    display: grid;
    grid-template-columns: 34px auto 36px;
    gap: 0.2rem;
}

.user-item-name {
    margin: auto auto auto 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
}

.user-item-subname {
    color: var(--p-primary-500);
    font-size: 12px;
}

.avatars {
    display: flex;
    list-style-type: none;
    padding: 0px;
    flex-direction: row;
    justify-content: flex-end;
}

.p-avatar {
    background-color: var(--p-primary-500);
    border: 2px solid #fff;
    border-radius: 100%;
    color: #ffffff;
    display: block;
    height: 45px;
    width: 45px;
    line-height: 42px;
    text-align: center;
    transition: margin 0.1s ease-in-out;
    overflow: hidden;
    margin-left: -10px;

    &:first-child {
        z-index: 5;
    }

    &:nth-child(2) {
        z-index: 4;
    }

    &:nth-child(3) {
        z-index: 3;
    }

    &:nth-child(4) {
        z-index: 2;
    }

    &:nth-child(5) {
        z-index: 1;
    }

    &:last-child {
        z-index: 0
    }

    img {
        width: 100%
    }
}

.avatars:hover {
    .p-avatar {
        margin-right: 10px;
    }
}

/* POIComponent */
.trip-poi-item {
    margin-bottom: 0.8em;
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 0.6em;
    font-size: 12px;
}

.trip-poi-infos {
    text-align: left;
}

.trip-poi-infos-core {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto 20px;
    gap: 0.4em;
    margin: auto 0;
}

.trip-poi-infos-more {
    width: 100%;
}

.trip-poi-type {
    flex-grow: 0;
    border: solid 2px var(--p-primary-color);
    border-radius: 18px;
    padding: 0.4em 0.6em;
    background-color: var(--p-primary-color)57;
    font-size: 12px;
    font-weight: 900;
    display: inline-flex;
    height: fit-content;
    box-sizing: border-box;
}

.trip-poi-type-label {
    margin-left: 6px;
}

@media screen and (max-width: 500px) {
    .trip-poi-type-label {
        display: none;
    }
}

.trip-poi-checkbox {
    margin: auto 0;
}

.trip-poi-name {
    flex-grow: 1;
    margin: auto 0;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 600;
}

.trip-poi-address {
    margin-top: 0.6em;
    color: #666;
    display: grid;
    grid-template-columns: 10px auto;
    gap: 0.4em;
}

.trip-poi-done {
    opacity: 0.25;
}

.trip-poi-description {
    margin-top: 0.6em;
    font-size: 13px;
    display: grid;
    grid-template-columns: 10px auto;
    gap: 0.4em;
}

.trip-poi-notes {
    margin-top: 0.6em;
    font-size: 13px;
    color: var(--p-primary-color);
    display: grid;
    grid-template-columns: 10px auto;
    gap: 0.4em;
}

.trip-poi-photo-refresh {
    position: absolute;
}
</style>