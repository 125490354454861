<template>
    <HeaderLogo />
    <div class="container">
        <div v-if="!authStore.user" class="auth-box">
            <h2>Login</h2>
            <form @submit.prevent="">
                <div class="input-group">
                    <label for="email">Email</label>
                    <input type="text" id="email" autocapitalize="off" v-model="email" required>
                </div>
                <div class="input-group">
                    <label for="password">Password</label>
                    <input type="password" id="password" autocapitalize="off" v-model="password" required>
                </div>
                <div v-if="!registrationForm" class="justify-center margin-vertical">

                    <div class="justify-center margin-vertical">
                        <Button label="Login" @click="login()" rounded></Button>
                        <div class="margin-vertical" v-if="message">
                            <Message severity="error">{{ message }}</Message>
                        </div>
                    </div>
                    No account?
                    <Button label="Sign Up" @click="registrationForm = true" rounded></Button>
                </div>
                <div v-if="registrationForm" class="justify-center margin-vertical">
                    <div class="input-group">
                        <label for="username">Username</label>
                        <input type="text" id="username" autocapitalize="off" v-model="username">
                    </div>
                    <div class="input-group">
                        <label for="firstName">First Name</label>
                        <input type="text" id="firstName" v-model="firstName">
                    </div>
                    <div class="input-group">
                        <label for="lastName">Last Name</label>
                        <input type="text" id="lastName" v-model="lastName">
                    </div>
                    <Button label="Create your account" @click="register()" rounded></Button>
                </div>
            </form>
        </div>
        <div class="profile" v-if="authStore.user">
            <div class="profile-header">
                Profile
            </div>
            <div class="margin-vertical">
                <div class="margin-vertical">
                    {{ authStore.user.firstName }} {{ authStore.user.lastName }}
                </div>
                <div class="margin-vertical">@{{ authStore.user.username }}</div>
                <div class="margin-vertical">{{ authStore.user.email }}</div>
            </div>
            <div class="justify-center margin-vertical" v-if="!authStore.user.isPremium">
                <input type="hidden" id="priceId" v-model="priceId" />
                <Button icon="pi pi-angle-double-up" label="Subscribe" @click="createCheckoutSession(priceId)"
                    rounded></Button>
            </div>
            <div class="profile-premium justify-center margin-vertical" v-if="authStore.user.isPremium">
                Premium access
            </div>
        </div>
    </div>
</template>

<script setup>
/*eslint-disable no-unused-vars*/
import { ref } from 'vue'
import { useAuthStore } from '@/stores/auth';
import HeaderLogo from "../components/HeaderLogo.vue";
import Button from 'primevue/button';
import Message from 'primevue/message';

const message = ref(null);
const registrationForm = ref(false);
const priceId = 'price_1PgiDHKe385wDNjelF899qrB';
const email = ref('');
const password = ref('');
const username = ref('');
const firstName = ref('');
const lastName = ref('');

const authStore = useAuthStore();

const login = async () => {
    const response = await authStore.login(email.value, password.value);
    if (response) {
        message.value = response.data;
    }
}
const register = async () => {
    await authStore.register(email.value, password.value, username.value, firstName.value, lastName.value);
}
const createCheckoutSession = async (priceId) => {
    const redirectUrl = await authStore.createCheckoutSession(priceId);
    window.location.href = redirectUrl;
}
</script>


<style scoped>
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    background: #fff;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
}

.auth-box {
    width: 100%;
}

.auth-box h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
    text-align: center;
}

.auth-current {
    margin-bottom: 20px;
}

.input-group {
    margin-bottom: 20px;
}

.input-group label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    color: #555;
}

.input-group input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: border-color 0.3s;
}

.input-group input:focus {
    border-color: #10b981;
    outline: none;
}

.profile {
    width: 100%;
    text-align: left;
}

.profile-header {
    text-align: left;
    font-size: 20px;
}

.profile-premium {
    width: fit-content;
    color: #fff;
    background-color: var(--p-primary-500);
    padding: 1rem;
    border-radius: 10px;
}
</style>