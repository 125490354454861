<template>
    <div class="listing" v-if="poiStore.places && poiStore.places.length > 0">
        <ul class="listing-list">
            <li class="listing-list-item" v-if="isLoadingPOIs === true || poiStore.places.length === 0">
                <div class="overtile">
                    <div style="display: flex; gap: 1em; margin-left: 1rem;">
                        <div style="margin: auto 0;">
                            <Skeleton height="5rem" width="6rem" class="mb-2"></Skeleton>
                        </div>
                        <div style="display: flex; flex-direction: column; gap: 1rem; justify-content: center;">
                            <Skeleton height="2rem" width="10rem" class="mb-2"></Skeleton>
                            <Skeleton height="1rem" width="10rem" class="mb-2"></Skeleton>
                        </div>
                    </div>
                </div>
            </li>
            <li class="listing-list-item" :class="{ done: place.done }" v-for="place in poiStore.places"
                :key="place._id" :ref="el => { places[place._id] = el }" @mouseover="highlightMarker(place._id)"
                @mouseleave="mapStore.backToDefaultMarkersState()">
                <div class="overtile">
                    <div v-if="place.image" class="place-image" :style="place.image ?
        'background-image: url(https://pub-b93990d14f63428d91fefbf1f1143791.r2.dev/' + place.image
        : 'background-color: #666'">
                        <!-- <img :src="'https://pub-b93990d14f63428d91fefbf1f1143791.r2.dev/' + place.image"
                            class="place-image-img" /> -->

                        <div class="place-open" v-if="isOpen(place) === true">
                            open now
                        </div>

                        <div class="place-url">
                            <a :href="place.googleMapsUri" target="_blank"><i class="fa-solid fa-link fa-sm"></i>
                                Maps</a>
                        </div>
                        <div class="place-url">
                            <a :href="place.websiteUri" target="_blank"><i class="fa-solid fa-link fa-sm"></i>
                                Website</a>
                        </div>
                    </div>
                    <div class="place-infos">
                        <div class="place-infos-core">
                            <div class="place-checkbox">
                                <input type="checkbox" id="checkbox" v-model="place.done"
                                    @change="check($event, place)" />
                            </div>
                            <div class="place-name">{{ place.name }}</div>
                            <div class="place-type">
                                <i :class="'fa ' + mapStore.getFaIcon(place.type) + ' fa-lg'"></i>
                                <span class="place-type-label">{{ poiStore.getLabel(place.type) }}</span>
                            </div>
                        </div>
                        <div class="place-infos-more">
                            <div class="place-description">
                                <i class="fa-solid fa-file-lines fa-sm"></i>
                                <div>
                                    <div v-if="place._id !== editedPlace._id" class="place-text"
                                        @click="enableEditing(place)">
                                        {{ place.description ? place.description : "<Ajouter description>"
                                            }}
                                    </div>
                                    <textarea v-if="place._id === editedPlace._id" v-model="place.description"
                                        class="place-text" @blur="updatePlace(place)"></textarea>
                                </div>
                            </div>
                            <div class="place-notes">
                                <i class="fa-solid fa-pen-nib fa-sm"></i>
                                <div>
                                    <div v-if="place._id !== editedPlace._id" class="place-text"
                                        @click="enableEditing(place)">
                                        {{ place.notes ? place.notes : "<Ajouter notes>"
                                            }}
                                    </div>
                                    <textarea v-if="place._id === editedPlace._id" v-model="place.notes"
                                        class="place-text" @blur="updatePlace(place)"></textarea>

                                </div>
                            </div>

                            <!-- <div class="place-hours" v-if="place.regularOpeningHours">
                                <div class="place-hours-entry"
                                    v-for="(slot, index) in place.regularOpeningHours.weekdayDescriptions" :key="slot">
                                    <div :class="{ 'place-hour-highlighted': index === NOW.getDay() }">{{ slot }}
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="place-hours" v-if="place.regularOpeningHours">
                                <div class="place-hours-entry"
                                    v-for="(slot, index) in place.regularOpeningHours.weekdayDescriptions" :key="slot">
                                    <div :class="{ 'place-hour-highlighted': index === NOW.getDay() }">{{ slot }}
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <div>
        </div>
    </div>
</template>

<script setup>
/*eslint-disable no-unused-vars*/
import { ref, watch, onBeforeUpdate, onMounted } from 'vue';
import { useMapStore } from '@/stores/map';
import { usePOIStore } from '@/stores/pois';
import Skeleton from 'primevue/skeleton';

const mapStore = useMapStore();
const poiStore = usePOIStore();
const isLoadingPOIs = ref(false);

const NOW = new Date();

const isOpen = (place) => {
    if (!place.regularOpeningHours || !place.regularOpeningHours.periods || !place.regularOpeningHours.periods[NOW.getDay()]) {
        return false;
    }
    if (place.regularOpeningHours.periods[0].open
        && !place.regularOpeningHours.periods[0].close
        && place.regularOpeningHours.periods[0].open.hour === 0
        && place.regularOpeningHours.periods[0].open.minute === 0) {
        // Open 24 hours
        return true;
    }
    return place.regularOpeningHours.periods[NOW.getDay()].open.hour < NOW.getHours()
        && place.regularOpeningHours.periods[NOW.getDay()].open.minute < NOW.getMinutes()
        && (
            (place.regularOpeningHours.periods[NOW.getDay()].close.hour > NOW.getHours() && place.regularOpeningHours.periods[NOW.getDay()].close.minute > NOW.getMinutes())
            ||
            (place.regularOpeningHours.periods[NOW.getDay()].close.day > NOW.getDay())
        );
}

const reloadPOIs = () => {
    isLoadingPOIs.value = true;
    poiStore.fetchPOIs().then(() => {
        isLoadingPOIs.value = false;
        mapStore.createGMarkers(poiStore.places);
    });
}

const highlightMarker = (id) => {
    mapStore.moveToMarker(id);
}

const check = (e, place) => {
    poiStore.updatePOI(place).then(() => {
        reloadPOIs();
    });
}

const places = ref({});
onBeforeUpdate(() => {
    places.value = {};
});
const scrollToPlace = (placeId) => {
    const el = places.value[placeId];
    if (el) {
        el.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'start' });
    }
}
watch(poiStore.currentPlace, (newValue, oldValue) => {
    scrollToPlace(newValue.value._id);
})

const editedPlace = ref({});
const enableEditing = (place) => {
    editedPlace.value = place;
}
const updatePlace = (place) => {
    poiStore.updatePOI(place);
    editedPlace.value = {};
}
</script>


<style scoped>
.listing {
    position: absolute;
    bottom: 60px;
    padding: 1em 0;
    width: 100vw;
}

.listing-list {
    display: flex;
    justify-content: space-evenly;
    overflow-x: scroll;
    margin: 0;
    max-height: 100%;
}

.listing-list::-webkit-scrollbar {
    display: none;
}

.listing-list-item {
    box-sizing: border-box;
    min-width: 100%;
    margin: 0;
}

.overtile {
    height: 100%;
    border: 1px solid #fff;
    border-radius: 16px;
    margin: 0 1em;
    padding-right: 0.4em;
    display: grid;
    grid-template-columns: 3fr 7fr;
    background-color: #fff;
}

.done {
    color: #999;
}

.place-checkbox {
    margin: auto 0;
}

.place-checkbox>input {
    margin: 0;
    width: 14px;
    height: 14px;
}

.place-infos {
    flex: 1;
    margin: auto 0;
    padding: 0.4em 0 0.4em 0.8em;
}

.place-infos-core {
    width: 100%;
    display: flex;
    gap: 0.4em;
    margin: auto 0;
}

.place-infos-more {
    width: 100%;
}

.place-type {
    flex-grow: 0;
    border: solid 2px var(--p-primary-color);
    border-radius: 18px;
    padding: 0.4em 0.6em;
    background-color: var(--p-primary-color)57;
    font-size: 14px;
    font-weight: 900;
    display: inline-flex;
    height: fit-content;
    box-sizing: border-box;
}

.place-type-label {
    vertical-align: middle;
    margin-left: 0.4em;
}

@media screen and (max-width: 600px) {
    .place-type-label {
        display: none;
    }
}

.place-type-icon {
    max-width: 20px;
    max-height: 20px;
    vertical-align: middle;
}

.place-name {
    margin: auto 0;
    font-size: 18px;
    font-weight: 500;
}

.place-description {
    font-size: 13px;
    color: #666;
    display: flex;
}

.place-notes {
    margin-top: 0.2em;
    font-size: 13px;
    color: var(--p-primary-700);
    display: flex;
}

.place-description>svg,
.place-notes>svg {
    width: 1em;
    margin: auto 0.3em auto 0;
}

.place-open {
    margin: 0.2em 0.2em 0.4em auto;
    padding: 0 0.4em;
    text-align: center;
    color: #fff;
    background-color: var(--p-primary-color);
    border-radius: 10px;
    width: fit-content;
    margin-bottom: auto;
}

.place-url {
    margin: 0.2em 0.2em 0.4em auto;
    padding: 0 0.4em;
    text-align: center;
    background-color: #45adff;
    border-radius: 10px;
    width: fit-content;
}

.place-url>a {
    font-size: 12px;
    font-weight: 900;
    color: #fff;
}

.place-hours {
    font-size: 11px;
}

.place-hour-highlighted {
    font-weight: bold;
    color: #45adff;
}

.place-image {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.place-image-img {
    border-radius: 3px;
    margin: auto;
    display: block;
    max-width: 100%;
    max-height: 20vh;
}
</style>