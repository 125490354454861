import axios from 'axios';
import { defineStore } from 'pinia';

import { useAuthStore } from '@/stores/auth';
import { useTripStore } from '@/stores/trip';

import { serverURL } from '../config';

export const useUserStore = defineStore('user-store', {
    state: () => {
        return {
            _foundUser: null,
            _users: []
        }
    },
    getters: {
        foundUser: (state) => state._foundUser,
        users: (state) => state._users
    },
    actions: {
        async fetchUsers(query) {
            const authStore = useAuthStore();
            try {
                this._users = [];
                const fullPath = "/users";
                const body = {
                    user: authStore.user,
                    query
                }
                const response = await axios.post(serverURL + fullPath, body);
                this._users = response.data;
            }
            catch (error) {
                alert(error);
                console.log(error);
            }
        },
        async addPOI(poi) {
            try {
                await axios.post(serverURL + "/pois", poi);
                this._foundPOI = null;
                const tripStore = useTripStore();
                tripStore.fetchTrips().then(() => {
                    this.fetchPOIs();
                });
            }
            catch (error) {
                alert(error);
                console.log(error);
            }
        },
        async updatePOI(poi) {
            try {
                await axios.put(serverURL + "/pois", poi);
                this.fetchPOIs();
            }
            catch (error) {
                alert(error);
                console.log(error);
            }
        },
        async findPOI(input, trip, isAccomodation) {
            try {
                const response = await axios.post(serverURL + "/search/place", {
                    input: input,
                    trip_id: trip._id,
                    location: {
                        latitude: trip.location[0],
                        longitude: trip.location[1]
                    },
                    accomodation: isAccomodation === true
                });
                this._foundPOI = response.data;
                return this._foundPOI;
            }
            catch (error) {
                alert(error);
                console.log(error);
            }
        }
    },
})