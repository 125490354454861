<template>
    <div v-if="isOpen" class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" ref="target">
                <div class="modal-header">
                    <slot name="header"> default header </slot>
                    <!--<button type="button" class="modal-close" @click="emit('modal-close')">
                        <i class="fa-solid fa-xmark"></i>
                    </button>-->
                </div>
                <div class="modal-body">
                    <slot name="content">Content</slot>
                </div>
                <div class="modal-footer">
                    <slot name="footer">
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
/*eslint-disable no-unused-vars*/
import { defineProps, defineEmits, ref } from "vue";
import { onClickOutside } from '@vueuse/core';

const props = defineProps({
    isOpen: Boolean,
});

const emit = defineEmits(["modal-close"]);

const target = ref(null)
onClickOutside(target, () => emit('modal-close'));

</script>


<style scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-container {
    width: 80vw;
    margin: 10vh auto;
    padding: 16px 20px;
    color: var(--p-primary-900);
    background-color: #fff;
    text-shadow: none;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
}

.modal-body {
    margin: 1rem 0;
}

.modal-close {
    float: right;
}
</style>