<template>
    <HeaderLogo />
    <div class="notes" v-if="tripStore.trips">
        <div>
            <div class="new-note-submit">
                <Button icon="pi pi-pencil" label="Add Note" @click="openModal()" rounded></Button>
            </div>
            <ModalComponent :isOpen="isModalOpened" @modal-close="closeModal" @submit="submitHandler"
                name="first-modal">
                <template #header>New note</template>
                <template #content>
                    <div class="new-note-form-item">
                        <textarea id="newNoteText" v-model="text" />
                    </div>
                </template>
                <template #footer>
                    <Button @click="addNote()">Validate</Button>
                </template>
            </ModalComponent>
        </div>
        <div class="notes-filters">
            <Select v-model="tripId" :options="tripStore.trips" optionLabel="name" optionValue="_id"
                placeholder="Select a Trip" class=""></Select>
        </div>
        <div class="note-list">
            <div class="note-list-item" v-for="note in noteStore.filteredNotes" :key="note._id">

                <Fieldset>
                    <template #legend>
                        <div class="note-header">
                            <Avatar :label="note.user.firstName.charAt(0) + note.user.lastName.charAt(0)"
                                shape="circle" />
                            <div class="user-item-name">
                                <div>
                                    {{ note.user.firstName }} {{ note.user.lastName.charAt(0) + "." }}
                                </div>
                                <div class="user-item-subname">
                                    {{ note.user.username }}
                                </div>
                            </div>
                        </div>
                    </template>

                    <div v-if="note._id !== editedNote._id" class="note-text" @click="enableEditing(note)">{{ note.text
                        }}</div>
                    <textarea v-if="note._id === editedNote._id" v-model="note.text" class="note-text"
                        @blur="updateNote(note)"></textarea>

                    <div class="note-footer">
                        <div class="note-date">
                            <!-- <Tag :value="note.trip.name" severity="secondary" rounded></Tag> -->

                            {{ new Date(note.createdAt).toLocaleDateString('fr-FR', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
                            }) }}
                        </div>
                        <div class="note-trip">
                            <Tag :value="note.trip.name" severity="secondary" rounded></Tag>
                        </div>
                        <div class="note-right">
                            <div class="note-delete" @click="deleteNote(note._id)"><i class="fa fa-trash fa-sm"></i>
                            </div>
                        </div>
                    </div>
                </Fieldset>
            </div>
        </div>
    </div>
</template>

<script setup>
/*eslint-disable no-unused-vars*/
import { ref, shallowRef, onMounted, watch, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import HeaderLogo from "../components/HeaderLogo.vue";
import ModalComponent from "../components/ModalComponent.vue";
import { useTripStore } from '@/stores/trip';
import { usePOIStore } from '@/stores/pois';
import { useNoteStore } from '@/stores/note';
import Button from 'primevue/button';
import Select from 'primevue/select';
import Fieldset from 'primevue/fieldset';
import Tag from 'primevue/tag';
import Avatar from 'primevue/avatar';

const router = useRouter();
const route = useRoute();

const tripStore = useTripStore();
const noteStore = useNoteStore();

const tripId = computed({
    get() {
        return route.query.tripId ? route.query.tripId : "";
    },
    set(tripId) {
        router.replace({ query: { tripId: tripId } })
    }
})

watch(() => route.query.tripId, (newId, oldId) => {
    tripId.value = newId;
    let selectedTrip = tripStore.trips.find(trip => tripId.value == trip._id);
    if (selectedTrip) {
        tripStore.setSelected(selectedTrip);
        noteStore.filterNotes(selectedTrip._id);
    }
})

onMounted(() => {
    if (tripStore.selected && tripStore.selected._id) {
        router.push({ path: '/notes', query: { tripId: tripStore.selected._id } });
    }
    tripStore.fetchTrips().then(() => {
        let selectedTrip = tripStore.trips.find(option => tripId.value == option.value);
        tripStore.setSelected(selectedTrip);
        noteStore.fetchNotes(selectedTrip ? selectedTrip._id : null);
    });
})

const isModalOpened = ref(false);

const openModal = () => {
    isModalOpened.value = true;
};
const closeModal = () => {
    isModalOpened.value = false;
};

const submitHandler = () => {
    //here you do whatever
}

const text = shallowRef("");

const addNote = () => {
    console.log(tripStore.selected);
    noteStore.addNote(text.value, tripStore.selected ? tripStore.selected._id : null);
    closeModal();
}

const deleteNote = (noteId) => {
    noteStore.deleteNote(noteId);
}

const editedNote = ref({});

const enableEditing = (note) => {
    editedNote.value = note;
}

const updateNote = (note) => {
    noteStore.updateNote(note);
    editedNote.value = {};
}

</script>

<style scoped>
.notes {
    box-sizing: border-box;
    overflow-y: scroll;
}

.notes::-webkit-scrollbar {
    display: none;
}

.notes-filters {
    margin: 0 0.6em;
}

.notes-filters>.p-select {
    width: 100%;
    text-align: left;
}

.note-list {
    margin-bottom: 60px;
}

.note-list-item {
    padding: 0.6em 0;
    margin: 0 0.6em;
    text-align: left;
}

.note-header {
    display: flex;
    gap: 0.5rem;
}

.note-header div {
    margin: auto 0;
}

.p-avatar {
    background-color: var(--p-primary-500);
    border: 2px solid #fff;
    border-radius: 100%;
    color: #ffffff;
    display: block;
    height: 45px;
    width: 45px;
    line-height: 42px;
    text-align: center;
    transition: margin 0.1s ease-in-out;
    overflow: hidden;
}

.note-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.note-right {
    margin: auto 0;
}

.note-date {
    font-size: 12px;
    font-weight: 600;
    margin: auto 0;
}

.note-trip {
    font-size: 11px;
}

.note-user {
    font-size: 12px;
    margin-top: 1em;
}

.note-user>svg {
    margin-right: 0.4em;
}

.note-pois {
    box-sizing: border-box;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.3em;
    margin-bottom: 0.6em;
}

.note-pois-extra {
    box-sizing: border-box;
    width: 100%;
}

.note-poi {
    flex: 1;
    display: grid;
}

.note-poi-name {
    margin: 0.2em 0;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.note-poi-image {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 3px;
    height: 140px;
    width: 100%;
    margin: auto;
}

.note-poi-mini {
    display: grid;
    gap: 0.3em;
    margin-bottom: 0.6em;
}

.note-poi-mini-image {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 3px;
    height: 110px;
    width: 100%;
    margin: auto;
}

.note-poi-image-img {
    flex-shrink: 0;
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
}

.note-poi-mini-image-img {
    flex-shrink: 0;
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
}

.note-poi-count {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 0.6em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.2em;
}

.note-poi-name-all {
    margin: 0.2em 0;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.note-poi-add {
    width: fit-content;
    border: none;
    border-radius: 20px;
    padding: 0.2em 0.6em;
    margin-bottom: 0.6em;
    background-color: var(--p-primary-color);
    color: #fff;
    font-weight: 900;
}

.note-poi-search {
    margin-bottom: 0.6em;
    display: flex;
    gap: 0.4em;
}

.note-poi-input {
    flex: 4;
    box-sizing: border-box;
    width: 100%;
    padding: 0.4em;
    font-size: 14px;
}

.note-poi-search-enter,
.note-poi-validate {
    flex: 1;
    max-width: fit-content;
    font-weight: 600;
    color: #fff;
    background-color: var(--p-primary-color);
    border-radius: 20px;
    padding: 0.6em;
}

.modal-container {
    width: 350px;
    max-width: 80%;
}

.new-note-form-item {
    width: 100%;
}

#newNoteText {
    width: 100%;
    font-size: 16px;
}

.new-note-dates {
    display: flex;
}

.new-note-submit {
    position: fixed;
    bottom: 60px;
    padding: 1em 0;
    z-index: 10;
    width: 100%;
}
</style>