<template>
    <div>
        <h1>Register</h1>
        <form @submit.prevent="register">
            <input v-model="username" placeholder="Username" required />
            <input v-model="password" type="password" placeholder="Password" required />
            <button type="submit">Register</button>
        </form>
    </div>
</template>

<script>
import { useAuthStore } from '@/stores/auth';

export default {
    data() {
        return {
            username: '',
            password: ''
        };
    },
    setup() {
        const authStore = useAuthStore();
        return { authStore };
    },
    methods: {
        async register() {
            await this.authStore.register(this.username, this.password);
        }
    }
};
</script>