/*eslint-disable no-unused-vars*/
import axios from 'axios';
import { defineStore } from 'pinia';

import { router } from '../router/index.js';

import { serverURL } from '../config';

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => {
        return {
            // initialize state from local storage to enable user to stay logged in
            //user: JSON.parse(localStorage.getItem('user')),
            //returnUrl: null
            _user: null,
            _token: localStorage.getItem('token') || '',
            _valid: null
        }
    },
    getters: {
        user: (state) => state._user,
        token: (state) => state._token,
        valid: (state) => state._valid
    },
    actions: {
        updateValidity(valid) {
            this._valid = valid;
        },
        async login(email, password) {
            try {
                const response = await axios.post(serverURL + "/auth/login", { email, password });
                this._token = response.data.token;
                localStorage.setItem('token', this._token);
                await this.getUser();
                router.push('/trips');
            } catch (error) {
                console.error('Login failed:', error);
                return error.response;
            }
        },
        async register(email, password, username, firstName, lastName) {
            try {
                await axios.post(serverURL + "/auth/register", { email, password, username, firstName, lastName });
                await this.login(email, password);
            } catch (error) {
                console.error('Registration failed:', error);
            }
        },
        async getUser() {
            if (!this._token) return;
            try {
                const response = await axios.get(serverURL + "/auth/user", {
                    headers: { Authorization: `Bearer ${this._token}` }
                });
                this._user = response.data;
            } catch (error) {
                console.error('Fetching user failed:', error);
                throw error;
            }
        },
        logout() {
            this._user = null;
            this._token = '';
            localStorage.removeItem('token');
            router.push('/profile');
        },
        async createCheckoutSession(priceId) {
            try {
                const resp = await axios.post(serverURL + "/create-checkout-session", { priceId });
                return resp.data;
            } catch (error) {
                console.error('Subscription failed:', error);
            }
        }
    }
});