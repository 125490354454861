import axios from 'axios';
import { defineStore } from 'pinia';

import { useAuthStore } from '@/stores/auth';
import { useTripStore } from '@/stores/trip';

import { serverURL } from '../config';

const LABELS = {
    "tourist_attraction": "attraction",
    "point_of_interest": "landmark",
    "landmark": "landmark",
    "monument": "landmark",
    "museum": "museum",
    "restaurant": "restaurant",
    "bar": "bar",
    "cafe": "café",
    "route": "street",
    "train_station": "train station",
    "housing": "housing",
    "car_rental": "car"
}

const cosineDistanceBetweenPoints = (lat1, lon1, lat2, lon2) => {
    const R = 6371e3;
    const p1 = lat1 * Math.PI / 180;
    const p2 = lat2 * Math.PI / 180;
    const deltaP = p2 - p1;
    const deltaLon = lon2 - lon1;
    const deltaLambda = (deltaLon * Math.PI) / 180;
    const a = Math.sin(deltaP / 2) * Math.sin(deltaP / 2) +
        Math.cos(p1) * Math.cos(p2) *
        Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);
    const d = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)) * R;
    return d;
}

export const usePOIStore = defineStore('poi-store', {
    state: () => {
        return {
            _foundPOI: null,
            _foundPOIs: [],
            _places: [],
            _currentPlace: {},
            _aiPlaces: []
        }
    },
    getters: {
        foundPOI: (state) => state._foundPOI,
        foundPOIs: (state) => state._foundPOIs,
        places: (state) => state._places,
        currentPlace: (state) => state._currentPlace,
        aiPlaces: (state) => state._aiPlaces,
    },
    actions: {
        getLabel(type) {
            if (type in LABELS) {
                return LABELS[type];
            } else {
                return type.split("_").join(" ");
            }
        },
        async fetchPOIs() {
            const tripStore = useTripStore();
            if (tripStore.selected) {
                const authStore = useAuthStore();
                try {
                    const fullPath = "/pois";
                    const params = {
                        user: authStore.user,
                        tripId: tripStore.selected._id
                    }
                    const response = await axios.get(serverURL + fullPath, {
                        params: params
                    });
                    this._places = response.data.map(p => {
                        let associatedDestination = tripStore.selected.destinations.reduce((bestConfig, dest) => {
                            const distance = cosineDistanceBetweenPoints(p.location.coordinates[0], p.location.coordinates[1], dest.location.coordinates[0], dest.location.coordinates[1]);
                            return distance < bestConfig.distance ? {
                                bestDestination: dest,
                                distance: distance
                            } : bestConfig
                        }, {
                            bestDestination: tripStore.selected.destinations[0],
                            distance: cosineDistanceBetweenPoints(p.location.coordinates[0], p.location.coordinates[1], tripStore.selected.destinations[0].location.coordinates[0], tripStore.selected.destinations[0].location.coordinates[1])
                        }).bestDestination;
                        return {
                            ...p,
                            infos: p.infos ? p.infos : "",
                            description: p.description ? p.description : "",
                            image: p.type === "housing" ? "airbnb.jpg" : p.image,
                            destination_place_id: associatedDestination.place_id
                        }
                    });
                }
                catch (error) {
                    alert(error);
                    console.log(error);
                }
            }
        },
        async addPOI(poi) {
            try {
                await axios.post(serverURL + "/pois", poi);
                //this._foundPOI = null;
                const tripStore = useTripStore();
                tripStore.fetchTrips().then(() => {
                    this.fetchPOIs();
                });
            }
            catch (error) {
                alert(error);
                console.log(error);
            }
        },
        async updatePOI(poi) {
            try {
                await axios.put(serverURL + "/pois", poi);
                this.fetchPOIs();
            }
            catch (error) {
                alert(error);
                console.log(error);
            }
        },
        async updatePOIPhoto(poiId) {
            try {
                await axios.put(serverURL + "/poi/photo", { poiId: poiId });
                this.fetchPOIs();
            }
            catch (error) {
                alert(error);
                console.log(error);
            }
        },
        async findPOI(input, trip, isAccomodation, location) {
            await this.findPOIs(input, trip, isAccomodation, location)[0];
            this._foundPOI = this._foundPOIs[0];
        },
        async findPOIs(input, trip, isAccomodation, location) {
            try {
                let request = {
                    input: input,
                    trip_id: trip._id,
                    accomodation: isAccomodation === true
                }
                if (location) {
                    request.location = {
                        latitude: location[0],
                        longitude: location[1]
                    }
                }
                const response = await axios.post(serverURL + "/search/place", request);
                this._foundPOIs = response.data;
                return this._foundPOIs;
            }
            catch (error) {
                alert(error);
                console.log(error);
            }
        },
        resetFoundPOI() {
            this._foundPOI = null;
        },
        async generatePOIs(styles, trip) {
            try {
                let request = {
                    styles: styles,
                    trip_id: trip._id
                }
                if (trip.location) {
                    request.location = {
                        latitude: trip.location[0],
                        longitude: trip.location[1]
                    }
                }
                const response = await axios.post(serverURL + "/ai/organizer", request);
                this._aiPlaces = response.data;
                return this._aiPlaces;
            }
            catch (error) {
                alert(error);
                console.log(error);
            }
        },
        resetAIPlaces() {
            this._aiPlaces = [];
        }
    },
})