<template>
  <div class="trips-section">
    <img class="logo" src="../assets/logo-min.png" />

    <Select v-model="tripId" :options="tripStore.trips" optionLabel="name" optionValue="_id" placeholder="Select a Trip"
      class=""></Select>

    <div class="trip-options">
      <div class="trip-poi-add" @click="openModal()">
        <div class="trip-poi-add-label"><i class="fa fa-plus fa-lg"></i> POI</div>
      </div>
      <div class="trip-poi-add" @click="openRouteMaker()">
        <div class="trip-poi-add-label"><i class="fa fa-route fa-lg"></i> Route<PremiumBadge></PremiumBadge>
        </div>
      </div>
    </div>

    <ModalComponent :isOpen="isModalOpened" @modal-close="closeModal" name="first-modal">
      <template #header>New POI</template>
      <template #content>
        <div>

          <div class="trip-poi-around">
            <Button icon="pi pi-map-marker" label="What is around me?"
              @click="searchPOI($event, tripStore.selected, mapStore.currentPosition)" rounded></Button>
          </div>

          <br />

          <div class="trip-poi-search">
            <InputText v-model="searchText" placeholder="Search by text"
              @keyup.enter="searchPOI($event, tripStore.selected, [mapStore.gmap.getCenter().lat(), mapStore.gmap.getCenter().lng()])" />
            <Button icon="pi pi-search"
              @click="searchPOI($event, tripStore.selected, [mapStore.gmap.getCenter().lat(), mapStore.gmap.getCenter().lng()])"
              rounded></Button>
          </div>
          <div class="new-poi-form-item">
            <div>Accomodation?</div>
            <ToggleButton v-model="selectedType" onLabel="Yes" offLabel="No" />
          </div>

        </div>
      </template>
      <template #footer>
        <ProgressSpinner v-if="isSearching" style="width: 50px; height: 50px" strokeWidth="8" fill="transparent"
          animationDuration=".5s" aria-label="Custom ProgressSpinner" />
        <div v-if="poiStore.foundPOIs">
          <div class="trip-poi-found" v-for="foundPOI in poiStore.foundPOIs" :key="foundPOI._id">
            <div class="trip-poi-image">
              <img :src="'https://pub-b93990d14f63428d91fefbf1f1143791.r2.dev/' + foundPOI.image" />
            </div>
            <div class="trip-poi-found-infos">
              <div>{{ foundPOI.name }}</div>
              <div>{{ foundPOI.address }}</div>
              <InputText type="text" v-model="foundPOI.notes" placeholder="Notes" />
            </div>
            <div class="ma">
              <Button icon="pi pi-plus" @click="addPOI(foundPOI)" size="small" rounded />
            </div>
          </div>
        </div>

      </template>
    </ModalComponent>

    <Drawer v-model:visible="routeMakerOpened" header="Route Maker" position="full">
      <div class="justify-center margin-vertical">
        <Message severity="info" icon="pi pi-info-circle">We will try to find a route to visit all the places of
          the trip that you have not seen yet!</Message>
      </div>
      <div class="flex justify-center">
        <Button
          @click="organizeRoute(tripStore.selected, [mapStore.gmap.getCenter().lat(), mapStore.gmap.getCenter().lng()])"
          :disabled="destination === ''" rounded>
          <i class="pi pi-sparkles"></i>Find Route<i class="pi pi-sparkles"></i>
        </Button>
      </div>
      <div>
        <div class="skeletons" v-if="isSearchingRoute === true">
          <Skeleton height="5rem" width="7rem" class="mb-2"></Skeleton>
          <Skeleton height="1rem" width="100%" class="mb-2"></Skeleton>
          <Skeleton height="1rem" width="100%" class="mb-2"></Skeleton>
        </div>
        <Stepper value="1">
          <StepItem v-for="(leg, idx) in tripStore.route" :key="leg._id" :value="idx">
            <StepPanel v-if="idx > 0">
              <div class="h-48">
                <div class="route-infos border-2 border-dashed border-surface-200 dark:border-surface-700 rounded">
                  <div class="route-transportation">{{ leg.transportation }}</div>
                  <div class="route-duration">{{ leg.duration }} minutes</div>
                </div>
              </div>
            </StepPanel>
            <Step>{{ leg.name }}</Step>
          </StepItem>
        </Stepper>
      </div>
    </Drawer>
  </div>
  <div id="gmap"></div>

  <PlaceListComponent />
</template>

<script setup>
/*eslint-disable no-unused-vars*/
import { ref, onMounted, watch, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Loader } from "@googlemaps/js-api-loader";
import PlaceListComponent from '../components/PlaceListComponent.vue';
import ModalComponent from "../components/ModalComponent.vue";
import PremiumBadge from '../components/PremiumBadge.vue';
import Select from 'primevue/select';
import Drawer from 'primevue/drawer';
import ProgressSpinner from 'primevue/progressspinner';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import ToggleButton from 'primevue/togglebutton';
import Message from 'primevue/message';
import Stepper from 'primevue/stepper';
import StepItem from 'primevue/stepitem';
import Step from 'primevue/step';
import StepPanel from 'primevue/steppanel';

import { useMapStore } from '@/stores/map';
import { useTripStore } from '@/stores/trip';
import { usePOIStore } from '@/stores/pois';

const router = useRouter();
const route = useRoute();

const mapStore = useMapStore();
const tripStore = useTripStore();
const poiStore = usePOIStore();

const tripId = computed({
  get() {
    if (!route.query.tripId && tripStore.selected) {
      return tripStore.selected._id;
    }
    return route.query.tripId ? route.query.tripId : "";
  },
  set(tripId) {
    router.replace({ query: { tripId: tripId, location: location.value } });
  }
})

const location = computed({
  get() {
    return route.query.location ? route.query.location : (tripStore.selected && tripStore.selected.location ? tripStore.selected.location.join(",") : "");
  },
  set(location) {
    router.replace({ query: { tripId: tripId.value, location: location } });
  }
})

watch(() => route.query.tripId, (newId, oldId) => {
  tripId.value = newId;
  tripStore.setSelected(tripStore.trips.find(option => tripId.value == option._id));
  if (tripStore.selected && tripStore.selected) {
    location.value = [tripStore.selected.destinations[0].location.coordinates[0], tripStore.selected.destinations[0].location.coordinates[1]].join(",");
    mapStore.moveTo(tripStore.selected.destinations[0].location.coordinates[0], tripStore.selected.destinations[0].location.coordinates[1]);
  }
  reloadPOIs();
  showTrips.value = false;
})
let showTrips = ref(false);

const reloadPOIs = () => {
  poiStore.fetchPOIs().then(() => {
    mapStore.createGMarkers(poiStore.places);
    if (tripStore.selected) {
      if (location.value != "") {
        const loc = location.value.split(",");
        mapStore.moveTo(loc[0], loc[1]);
      } else {
        mapStore.moveTo(tripStore.selected.destinations[0].location.coordinates[0], tripStore.selected.destinations[0].location.coordinates[1]);
      }
    }
  })
}

const setCurrentPosition = (latitude, longitude) => {
  //mapStore.moveTo(latitude, longitude);
  mapStore.setCurrentPositionWithMarker(latitude, longitude);
}

const isModalOpened = ref(false);
const openModal = () => {
  isModalOpened.value = true;
};
const closeModal = () => {
  isModalOpened.value = false;
};

const selectedType = ref(false);

const searchText = ref("");
const isSearching = ref(false);
const poiNotes = ref("");

const searchPOI = async (e, trip, location) => {
  isSearching.value = true;
  poiStore.resetFoundPOI();
  await poiStore.findPOI(searchText.value, trip, selectedType.value, location);
  isSearching.value = false;
}

const addPOI = (foundPOI) => {
  poiStore.addPOI(foundPOI);
  searchText.value = "";
  poiNotes.value = ""
}

// Route Drawer
const routeMakerOpened = ref(false);
const openRouteMaker = () => {
  routeMakerOpened.value = true;
};
const closeRouteMaker = () => {
  routeMakerOpened.value = false;
};
const isSearchingRoute = ref(false);
const organizeRoute = async (trip, location) => {
  isSearchingRoute.value = true;
  await tripStore.organizeRoute(trip, location);
  isSearchingRoute.value = false;
}

const mapOptions = {
  /*center: {
    lat: 48.864716,
    lng: 2.349014
  },*/
  zoom: 12,
  disableDefaultUI: true,
  gestureHandling: "greedy",
  mapId: "68d4b3af2d7f081f"//mapStore.id
};

const loader = new Loader({
  apiKey: "AIzaSyBpT4KFrKzOE-ojStyDbroIR2MFAs2v_h0",//mapStore.key,
  version: "beta",
  libraries: ["maps", "places", "marker"]
});

onMounted(() => {
  tripStore.fetchTrips().then(() => {
    if (tripStore.selected && tripStore.selected._id) {
      router.push({ path: '/map', query: { tripId: tripStore.selected._id, location: location.value } });
    } else if (tripStore.trips && tripStore.trips.length > 0) {
      router.replace({ query: { tripId: tripStore.trips[0]._id, location: tripStore.trips[0].destinations[0].location.coordinates.join(",") } });
    }
  });

  //// GMaps
  mapStore.setLoader(loader);
  loader
    .importLibrary("maps", "places", "marker")
    .then(({ Map }) => {
      mapStore.setGMap(new Map(document.getElementById("gmap"), mapOptions));
      reloadPOIs();

      navigator.geolocation.getCurrentPosition(
        position => {
          console.log("Current position", position.coords.latitude, position.coords.longitude);
          setCurrentPosition(position.coords.latitude, position.coords.longitude);
        },
        error => {
          console.log(error.message);
        },
      )
    })
    .catch((e) => {
      // do something
    });
})
</script>

<style>
.logo {
  margin: auto;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin: auto 0;
}

#gmap {
  width: 100%;
  height: 100%;
  position: static !important;
}

h4 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

.hide {
  display: none;
}

.leaflet-container {
  font-family: unset;
}

.submap {
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  position: fixed;
  bottom: 60px;
  height: 6vh;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 499;
  border-radius: 32px 32px 0px 0px;
}

.submap::-webkit-scrollbar {
  display: none;
}

.expandedSubmap {
  height: 30vh;
}

.submap-show-icon {
  position: sticky;
  width: 20px;
  height: 20px;
}

.listings {
  max-height: 100%;
}

.trips-section {
  position: absolute;
  z-index: 1;
  box-sizing: border-box;
  width: 100vw;
  padding: 1em;
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-rows: 1fr;
  gap: 1em;
}

.trips-section>.p-select {
  text-align: left;
  overflow: hidden;
}

.trip-options {
  display: flex;
  gap: 0.6em;
}

#map {
  background: black;
}

.listing {
  flex: 1;
  text-align: left;
  display: block;
  padding: 0.6em 0.4em;
}

.listing-header {
  display: flex;
  gap: 1em;
  margin-top: 0;
}

.listing-icon {
  max-width: 1.6em;
  margin: auto;
}

.listing-header-text {
  flex: 1;
  font-size: large;
}

.gm-style-iw-d {
  text-align: justify;
}

.trip-poi-add {
  width: fit-content;
  white-space: nowrap;
  border: none;
  border-radius: 20px;
  padding: 0.8em 0.6em;
  background-color: var(--p-primary-color);
  color: #fff;
  font-weight: 600;
}

.trip-poi-add-label {
  font-size: 12px;
}

.trip-poi-search {
  display: flex;
  justify-content: space-between;
  gap: 0.4em;
}

.trip-poi-found {
  width: 100%;
  margin: 1rem 0;
  display: grid;
  grid-template-columns: 40% auto 40px;
  gap: 1em;
  font-size: 11px;
  text-wrap: pretty;
}

.trip-poi-image {
  margin: auto 0;
}

.trip-poi-image img {
  max-height: 12vh;
  max-width: 100%;
}

.trip-poi-found .p-inputtext {
  width: 100%;
  font-size: 11px;
  height: 3em;
  margin: auto 0;
}

.trip-poi-found-infos {
  display: flex;
  flex-direction: column;
  gap: 0.4em;
  text-align: left;
}

.trip-poi-validate {
  max-width: fit-content;
  font-weight: 600;
  color: #fff;
  background-color: var(--p-primary-color);
  border-radius: 20px;
  padding: 0.6em;
  margin: auto;
}

.modal-container {
  width: 350px;
  max-width: 80%;
}

.new-poi-form-item {
  display: flex;
  justify-content: space-between;
  gap: 1em;
  margin: 1rem 0;
}

.new-poi-form-item>div:first-child {
  margin: auto 0;
}

.new-poi-form-item .p-select {
  flex-grow: 1;
}

.route-transportation {
  color: var(--p-primary-800);
}

.route-duration {
  color: var(--p-surface-500);
  margin-top: 0.6em;
  float: right;
}
</style>
