/*eslint-disable no-unused-vars*/
import axios from 'axios';
import { shallowRef } from 'vue';
import { defineStore } from 'pinia';
import { serverURL } from '../config';
import { useAuthStore } from '@/stores/auth';

export const useNoteStore = defineStore('note-store', {
    state: () => {
        return {
            _notes: shallowRef([]),
            _filteredNotes: shallowRef([])
        }
    },
    getters: {
        notes: (state) => state._notes,
        filteredNotes: (state) => state._filteredNotes
    },
    actions: {
        async fetchNotes(tripId, poiId) {
            try {
                const authStore = useAuthStore();
                const response = await axios.get(serverURL + "/notes", {
                    params: {
                        user: authStore.user,
                        //trip_id: tripId,
                        //poi_id: poiId
                    }
                });
                this._notes = response.data;
                this.filterNotes(tripId);
            }
            catch (error) {
                alert(error);
                console.log(error);
            }
        },
        setSelected(selectedOptions) {
            this._selected = selectedOptions;
        },
        filterNotes(tripId) {
            if (tripId) {
                this._filteredNotes = this._notes.filter(note => note.trip_id === tripId);
            } else {
                this._filteredNotes = this._notes;
            }
        },
        async addNote(text, tripId) {
            let note = {
                text: text,
                createdAt: Date.now(),
                trip_id: tripId
            };
            try {
                await axios.post(serverURL + "/note", note);
                this.fetchNotes();
            }
            catch (error) {
                alert(error);
                console.log(error);
            }
        },
        async updateNote(note) {
            try {
                await axios.put(serverURL + "/note", note);
                this.fetchNotes();
            }
            catch (error) {
                alert(error);
                console.log(error);
            }
        },
        async deleteNote(noteId) {
            try {
                await axios.delete(serverURL + "/note/" + noteId);
                this.fetchNotes();
            }
            catch (error) {
                alert(error);
                console.log(error);
            }
        }
    },
})