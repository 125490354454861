/*eslint-disable no-unused-vars*/
import axios from 'axios';
import { ref } from 'vue';
import { defineStore } from 'pinia';
import { serverURL } from '../config';
import { useAuthStore } from '@/stores/auth';

const dateDiffInDays = (a, b) => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;

    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export const useTripStore = defineStore('trip-store', {
    state: () => {
        return {
            _trips: [],
            _currentTrip: {},
            _nextTrips: [],
            _pastTrips: [],
            _selected: null,
            _foundLocation: null,
            _nextDestination: null,
            _route: []
        }
    },
    getters: {
        trips: (state) => state._trips,
        currentTrip: (state) => state._currentTrip,
        nextTrips: (state) => state._nextTrips,
        pastTrips: (state) => state._pastTrips,
        selected: (state) => state._selected,
        foundLocation: (state) => state._foundLocation,
        nextDestination: (state) => state._nextDestination,
        route: (state) => state._route,
    },
    actions: {
        async fetchTrips() {
            try {
                const authStore = useAuthStore();
                const tripsResponse = await axios.get(serverURL + "/trips", {
                    params: {
                        pois: false
                    }
                });
                this._trips = tripsResponse.data;
                this._nextTrips = this._trips.filter(trip => Date.parse(trip.dates[0]) > Date.now()).sort((a, b) => Date.parse(a.dates[0]) - Date.parse(b.dates[0]));
                this._pastTrips = this._trips.filter(trip => Date.parse(trip.dates[1]) < Date.now()).sort((a, b) => Date.parse(b.dates[0]) - Date.parse(a.dates[0]));
                this._currentTrip = this._trips.find(trip => Date.parse(trip.dates[0]) < Date.now() && Date.parse(trip.dates[1]) > Date.now());
                if (!this._currentTrip) {
                    this._currentTrip = {}
                }
            }
            catch (error) {
                alert(error);
                console.log(error);
            }
        },
        setSelected(selectedOption) {
            this._selected = selectedOption;
        },
        async findLocation(destination, country, dates) {
            try {
                const response = await axios.post(serverURL + "/search/place", {
                    input: destination
                });
                this._newTripName = name;
                this._foundLocation = response.data;
                // Add an empty location for the timeline
                if (this._foundLocation.destinations) {
                    // Enrich destination with its dates
                    this._foundLocation.destinations[0].dates = dates;
                    this._foundLocation.destinations.push({});
                }
                return this._foundLocation;
            }
            catch (error) {
                alert(error);
                console.log(error);
            }
        },
        async searchDestination(searchText) {
            try {
                const response = await axios.post(serverURL + "/search/place", {
                    input: searchText
                });
                this._newTripName = name;
                this._nextDestination = response.data.destinations[0];
                return this._nextDestination;
            }
            catch (error) {
                alert(error);
                console.log(error);
            }
        },
        addNextDestination(dates) {
            if (this._foundLocation.destinations) {
                const tripLength = this._foundLocation.destinations.length;
                this._nextDestination.dates = dates;
                this._foundLocation.destinations[tripLength - 1] = this._nextDestination;
                this._foundLocation.destinations.push({});
                this._nextDestination = null;
            }
        },
        async addTrip(name, startDate, endDate, reason) {
            let trip = {
                name,
                reason: reason,
                destinations: this._foundLocation.destinations.filter(dest => dest.place_id !== undefined)
            };

            let destinationCount = trip.destinations.length;
            trip.dates = [trip.destinations[0].dates[0], trip.destinations[destinationCount - 1].dates[1]];
            try {
                await axios.post(serverURL + "/trip", trip);
                this.fetchTrips();
            }
            catch (error) {
                alert(error);
                console.log(error);
            }
        },
        async updateTripPhoto(tripId) {
            console.log(tripId);
            try {
                await axios.put(serverURL + "/trip/photo", { tripId: tripId });
                this.fetchTrips();
            }
            catch (error) {
                alert(error);
                console.log(error);
            }
        },
        async addUser(tripId, userId) {
            console.log(tripId);
            try {
                await axios.put(serverURL + "/trip/" + tripId, { userId, action: "addUser" });
                this.fetchTrips();
            }
            catch (error) {
                alert(error);
                console.log(error);
            }
        },
        async removeUser(tripId, userId) {
            console.log(tripId);
            try {
                await axios.put(serverURL + "/trip/" + tripId, { userId, action: "removeUser" });
                this.fetchTrips();
            }
            catch (error) {
                alert(error);
                console.log(error);
            }
        },
        async organizeRoute(trip, location) {
            try {
                const response = await axios.post(serverURL + "/ai/route", {
                    location: location,
                    trip_id: trip._id
                });
                this._route = response.data.route;
            }
            catch (error) {
                alert(error);
                console.log(error);
            }
        }
    },
})